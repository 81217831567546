<template>
    <v-main>
        <div class="main-content">
            <Card class="card-custom">
                <template #title>
                    <Toolbar>
                        <template #start>
                            <!-- <h3>{{ $t('jackpot_settings') }}</h3> -->
                            <div class="p-inputgroup" v-if='admin.level === 0'>
                                <Button
                                v-if="filters.agent_id"
                                @click.stop="clearSelectedFilter('agent_id')"
                                icon="pi pi-times" class="p-button-danger"/>
                                  <Dropdown 
                                  @change="getJackpots"
                                  class="custom-dropdown"
                                  :options="agents.items"
                                  v-model="filters.agent_id"
                                  optionLabel="login"
                                  optionValue="id"
                                  :placeholder="$t('agent')"
                                  :filter="true"
                                  :emptyFilterMessage="$t('no_data')"
                                ></Dropdown>
                              </div>
                        </template>
                        <!-- v-tooltip.left="jackpots.length > 0 ? $t('only_one_jackpot') : $t('create_jackpot')"  
                        :disabled="jackpots.length > 0"
                        -->
                        <template #end>
                            <div class="space-x-1 d-flex align-center">
                                
                                    <Button 
                                    :label="$t('create_jackpot')" 
                                    @click="toCreateJackpot"
                                    icon="pi pi-plus" 
                                    class="p-button-sm p-button-success" />
                            </div>
                        </template>
                    </Toolbar>
                    </template>
                <template #content>
                    <DataTable 
                    removableSort
                                    responsiveLayout="scroll"
                                    :value="sortJackpotsByStatus" 
                                    :rows="10"
                                    :loading="loadingFlag" 
                                    :paginator="false"
                                    stripedRows class="p-datatable-sm">
                                    <template #empty>
                                        {{ $t('no_data') }}
                                    </template>
                                    <Column field="id" header="ID" :sortable="true" :styles="{'width':'10rem'}">
                                        <template #body="{data}">  
                                        <div>
                                            {{ data.id }}
                                            </div>
                                            </template>                      
                                    </Column>
                                    <Column field="started_date" :header="$t('date_start')" :sortable="true" :styles="{'min-width':'10rem'}">
                                        <template #body="{data}">  
                                        <div>
                                            {{ data.started_date ? new Date (data.started_date).toLocaleString() : '-' }}
                                            </div>
                                            </template>                      
                                    </Column>
                                    <Column field="finished_date" :header="$t('date_end')" :sortable="true" :styles="{'min-width':'10rem'}">
                                        <template #body="{data}">  
                                        <div>
                                            {{ data.finished_date ? new Date (data.finished_date).toLocaleString() : data.finished_date ? data.finished_date : '-' }}
                                            </div>
                                            </template>                      
                                    </Column>
                                    <Column field="start_win_amount" :header="$t('jackpot_progress')" :styles="{'min-width':'10rem'}">
                                        <template #body="{data}">  
                                            <div class="d-flex flex-column align-center justify-start">
                                                <div class="d-flex align-center">
                                                    <div class="amount-value">
                                                        {{ data.start_win_amount }} 
                                                    </div>
                                                    <progress-bar
                                                    :height="30"
                                                    :showTip="false"
                                                    class="custom-transform"
                                                    :width="175"
                                                    innerStrokeColor="#D1D1D1"
                                                    startColor="var(--main-flamingo)"
                                                    stopColor="var(--red)"
                                                       :completed-steps="getCompletedSteps(data.bets_progress, data.target_win_amount)"
                                                       :total-steps="data.target_win_amount"
                                                     >
                                                       <!-- <template v-slot:header>this is header slot </template>
                                                       <template v-slot:footer> this is footer slot</template> -->
                                                       <!-- <template v-slot:tip> 
                                                           <div class="d-flex align-center justify-center">
                                                            {{ data.bets_progress }}
                                                        </div>
    
    
                                                       </template> -->
                                                     </progress-bar>
                                                     <div class="amount-value">
                                                        {{ data.target_win_amount }}
                                                    </div>
                                                </div>
                                                <div class="text-center">
                                                   <!-- <b> {{ data.bets_progress }} / {{ data.target_win_amount }}</b> -->
                                                   <b> {{ data.bets_progress }}</b>
                                                </div>
                                            </div>
                                        <div>
                                                
                                        </div>
                                            </template>                      
                                    </Column>
                                    <!-- <Column field="target_win_amount" :header="$t('target_win_amount')" :sortable="true" :styles="{'min-width':'10rem'}">
                                        <template #body="{data}">  
                                        <div>
                                                {{ data.target_win_amount }}
                                        </div>
                                            </template>                      
                                    </Column>
                                    <Column field="bet_percent" :header="$t('bet_percent')" :sortable="true" :styles="{'min-width':'10rem'}">
                                        <template #body="{data}">  
                                        <div>
                                                {{ data.bet_percent }}
                                        </div>
                                            </template>                      
                                    </Column> -->
                                    <Column field="currency" :header="$t('currency')" :sortable="true" :styles="{'min-width':'10rem'}">
                                        <template #body="{data}">  
                                        <div>
                                                {{ data.currency }}
                                        </div>
                                            </template>                      
                                    </Column>
                                    <Column field="agent_id" :header="$t('agent_id')" :sortable="true" :styles="{'min-width':'10rem'}">
                                        <template #body="{data}">  
                                        <div>
                                                {{ data.agent_id ? data.agent_id : '-' }}
                                        </div>
                                            </template>                      
                                    </Column>
                                    <Column field="status" :header="$t('status')" :sortable="true" :styles="{'min-width':'10rem'}">
                                        <template #body="{data}">  
                                        <div class="d-flex align-center">
                                            <span class="custom-badge"
                                            v-tooltip.top="getTooltipByStatus(data.status)"
                                            :class="getClassByStatus(data.status)"
                                            >
                                            {{$t(data.status)}}
                                            
                                        </span>
                                        <!-- @change="applyFilters" -->
                                        <!-- :binary="true" -->

                                        <!-- true-value="ongoing"
                                        false-value="finished" -->
                                        <!-- :disabled="data.status !== 'ongoing' ? false : true" -->
                                        <Checkbox id="jackpot" 
                                        v-if="data.status !== 'finished'"
                                        v-tooltip.top="data.status !== 'ongoing' ? $t('activated_jackpot_hint') : $t('active_jackpot_hint')"
                                        :modelValue="data.status === 'ongoing'"
                                        :value="data.status === 'ongoing'"
                                        :binary="true"
                                        @change="toActiveJackpot(data)"
                                        class="p-checkbox-success ml-1"
                              />
                                         <!-- {{ data.status }} -->
                                            <!-- <span :class="'custom-badge status-' + data.status">{{data.status}}</span> -->
                                            <!-- 'unqualified', 'qualified', 'new', 'negotiation', 'renewal', 'proposal' -->
                                        </div>
                                            </template>                      
                                    </Column>
                                    <Column field="user_id" :header="$t('player_winner_id')" :sortable="true" :styles="{'min-width':'10rem'}">
                                        <template #body="{data}">  
                                            <router-link v-if="data.user_id" :to="{
                                                name: 'agent_players',
                                                params: { player_id: data.user_id }
                                            }">
                                                {{ data.user_id }}
                                            </router-link>
                                        <div v-else>
                                            -
                                        </div>
                                            </template>                      
                                    </Column>
                                    <!--<Column field="game_id" :header="$t('game_id')" :sortable="true" :styles="{'min-width':'10rem'}">
                                        <template #body="{data}">  
                                        <div class="d-flex align-center">
                                            {{ data.game_id ? data.game_id : '-' }}
                                             <v-lazy v-model="isActive">
                                                <div class="wrapper-item" :class="getClassByIndex(index)">
                                
                                                        <v-img max-height="40px" max-width="40px" 
                                                        @load="isActive = true" 
                                                        :src="`https://minio.takemybet.pro/games/${data.game_logo}`" 
                                                        :alt="data.game_name" />
                                                    </div>
                                                </v-lazy>
                                                <div>
                                                    {{ getGame(game_name) }}
                                                </div> 
                                        </div>
                                            </template>                      
                                    </Column>-->

                                    
                                    <Column :exportable="false" :styles="{'width':'40rem'}">
                                        <template #body="{data, index}">
                                            <span class="d-flex align-center"
                                            :class="{ 'p-buttonset' : data.status !== 'finished' }"
                                            >
                                                <Button 
                                                v-if="data.status !== 'finished'"
                                                @click="toEditJackpot(data)" :disabled="loadingFlag || data.loadingItem" :loading="loadingFlag || data.loadingItem" :label="$t('edit')"
                                                icon="pi pi-pencil" class="p-button-sm p-button-warning" />
                                                <Button @click="toDeleteJackpot(index)" 
                                                :disabled="loadingFlag || data.loadingItem" :loading="loadingFlag || data.loadingItem" 
                                                :label="$t('delete')"
                                                aria:haspopup="true" :aria-controls="`overlay_panel_${index}`"
                                                icon="pi pi-trash" class="p-button-sm p-button-danger" />
                                            </span>
                                            <!-- class="admin-overlay" -->
                                            <OverlayPanel :ref="`op_${index}`" appendTo="body" :id="`overlay_panel_${index}`" class="p-confirm-popup">
                                                <div class="p-confirm-popup-content">
                                                <i class="p-confirm-popup-icon pi pi-exclamation-triangle"></i>
                                                <div class="p-confirm-popup-message d-flex flex-column" 
                                style="max-width:400px"
                                >
                                    <h3>{{ $t('attention_title') }}</h3>
                                    <span>{{ $t('jackpot_will_deleted') }}</span>
                                    <h4 style="margin-top: 5px;">{{ $t('attention_question') }}</h4>
                                </div>
                                                </div>
                                                <div class="p-confirm-popup-footer">
                                                    <span class="p-buttonset">
                                                        <Button @click="toCloseOverlay(index)" :disabled="data.loadingItem" :loading="data.loadingItem" 
                                                        :label="$t('no')"
                                                        class="p-button-sm p-button-warning" />
                                                        <Button @click="toConfirmDelete(data, index)" 
                                                        :disabled="data.loadingItem" :loading="data.loadingItem" 
                                                        :label="$t('confirm')"
                                                        class="p-button-sm p-button-danger" />
                                                    </span>
                                                </div>
                                            </OverlayPanel>
                                        </template>
                                    </Column>
                                    </DataTable>
                    </template>
                    </Card>
            <!-- <modal-jackpot-create
            @added="getJackpots"
              :toAddDialog="toAddDialog" 
              @dialog="toAddDialog = false"
            />       -->
            <modal-jackpot-handle
            @added="getJackpots"
            :isEdit="isEditJackpot"
            :currentJackpot="currentJackpot"
              :toAddDialog="modalHandleJackpot" 
              @dialog="modalHandleJackpot = false"
            />      
        </div>
    </v-main>
</template>

<script>
import ModalJackpotHandle from './ModalJackpotHandle'
import { mapGetters } from "vuex";
import ProgressBar from "vue2-progress-bar";
    export default {
        name: "JackpotSettings",
        components:{
          ModalJackpotHandle,
          ProgressBar
        },
        data() {
            return {
                modalHandleJackpot: false,
                currentJackpot: {},
                isEditJackpot: false,
                modalCreateJackpot: false,
                modalHandleJackpot: false,
                loadingFlag: false,
                filters: {
                    agent_id: null
                },
                allAgentsFilter:{
                  page: 1,
                  size: 100,
                  is_active: true,
                },
            }
        },
        computed: {
            ...mapGetters({
                jackpots: 'jackpots/getJackpots',
                tokenUser: 'tokenUser',
                agents: 'agents/getAgents',
                admin: 'admin',
            }),
            sortJackpotsByStatus() {
                const statusOrder = {
                    ongoing: 1,
                    created: 2,
                    finished: 3
                };

                return this.jackpots.sort((a, b) => {
                    return statusOrder[a.status] - statusOrder[b.status];
                });
            }
        },
        async mounted() {
            this.loadingFlag = true;
            if (!this.agents.length) {
            await this.getAgents();
            }
            await this.getJackpots();
            this.loadingFlag = false;
        },
        beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.$store.commit('setPageName', 'jackpot_settings')
            })
        },
        methods:{
            toActiveJackpot(jackpot){
                if (jackpot.status === 'ongoing') {
                    jackpot.status = 'created';
                } else{
                    jackpot.status = 'ongoing';
                }
                this.updateJackpot(jackpot);
            },
            async updateJackpot(jackpot){
                this.loadingFlag = true;
                try {
                    await this.$store.dispatch('jackpots/awaitEditJackpot', jackpot);
                    await this.$store.dispatch('getAgentStatus')
                    if (jackpot.status === 'ongoing') {
                        this.$toast.add({ severity: 'success', summary: this.$t('jackpot_enabled'), life: 4000 })
                        
                    } else{

                        this.$toast.add({ severity: 'info', summary: this.$t('jackpot_disabled'), life: 4000 })
                    }
                    await this.getJackpots();
                } catch (error) {
                    this.$toast.add({ severity: 'error', summary: this.$t('just_error'), detail: error.message, life: 4000 })
                } finally{
                    this.loadingFlag = false;
                }
            },
            async getAgents() {
            try {
              this.loadingFlag = true;
            //   await this.$store.dispatch('agents/awaitGetAgents', {type: 'agent', filters: this.allAgentsFilter});
            // await this.$store.dispatch('agents/awaitGetAllAdminAgents', {type: null, filters: this.allAgentsFilter});
            await this.$store.dispatch("agents/awaitGetAgents", this.allAgentsFilter);
            } catch (error) {
              this.$toast.add({ severity: 'error', summary: this.$t('just_error'), detail: error.message, life: 4000 })
            } finally{
              this.loadingFlag = false;
            }
          },
            clearSelectedFilter(key){
                this.filters[key] = null;
                this.getJackpots();
            },
            getClassByStatus(status){
                switch (status) {
                    case 'created':
                        return '_gray'
                    case 'ongoing':
                        return '_green'
                    case 'finished':
                        return '_yellow'
                    default:
                        return '_gray'
                }
            },
            getTooltipByStatus(status){
                switch (status) {
                    case 'created':
                        return this.$t('created_tooltip_jackpot')
                    case 'ongoing':
                        return this.$t('ongoing_tooltip_jackpot')
                    case 'finished':
                        return this.$t('finished_tooltip_jackpot')
                    default:
                        return this.$t('created_tooltip_jackpot')
                }
            },
            getCompletedSteps(progress, target){
                return progress >= target ? target : progress
            },
            async getJackpots(){
                this.isEditJackpot = false;
                this.modalHandleJackpot = false;
                this.loadingFlag = true;
                try {
                    
                    await this.$store.dispatch('jackpots/awaitGetJackpots', this.filters.agent_id);
                } catch (error) {
                    this.$toast.add({ severity: 'error', 
                        summary: this.$t('just_error'),
                        datail: error,
                         life: 4000 });
                } finally{
                    this.loadingFlag = false;
                }
            },
            toEditJackpot(data){
                this.modalHandleJackpot = true;
                this.isEditJackpot = true;
                this.currentJackpot = {...data};
            },
            toCloseOverlay(index){
                this.$refs[`op_${index}`].hide(event);
            },
            toDeleteJackpot(index){
                this.$refs[`op_${index}`].toggle(event);
            },
            toCreateJackpot(){
                this.modalHandleJackpot = true;
                this.isEditJackpot = false;
                this.currentJackpot = {};
            },
            async toConfirmDelete(jack, index){
                    this.$refs[`op_${index}`].hide(event);
                    jack.loadingItem = true;
                    try {
                        await this.$store.dispatch('jackpots/awaitDeleteJackpot', jack.id);
                        await this.$store.dispatch('getAgentStatus');
                        this.$toast.add({ severity: 'success', 
                        summary: this.$t('jackpot_deleted'),
                        life: 4000 });
                        this.getJackpots();
                    } catch (error) {
                        this.$toast.add({ severity: 'error', 
                        summary: this.$t('just_error'),
                        datail: error,
                         life: 4000 });
                    } finally{
                        jack.loadingItem = true;
                    }
            },
        },
    }
</script>

<style lang="scss" scoped>
.custom-transform{
    transform: translate(0px,3px);
}
.amount-value{
    font-size: 20px;
    font-weight: 700;
    width: 60px;
    display: flex;
    justify-content:center;align-items: center;
    color: var(--text-color);
    &:first-child{
        justify-content: flex-end;
    }
    &:last-child{
        justify-content: flex-start;
    }
}
</style>