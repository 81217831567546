<template>
    <v-dialog
          v-model="dialog"
          max-width="600px"
          :persistent="formLoading"
          transition
          >
          <!-- scrollable -->
            <v-card
            v-if="showAdd"
            >
              <v-card-title class="font-weight-bold justify-center">
                <span>{{ isEdit ? $i18n.t("edit_jackpot") : $i18n.t("new_jackpot") }}</span>
              </v-card-title>
              <ValidationObserver ref="observer" v-slot="{ invalid, validate }">
              <v-card-text class="pb-0 pt-1 text-left space-y-2">
                <Message v-if="errorCount || errorTarget" severity="error" :closable="false">
                  <div
                  v-html="errorCount ? $t('agent_no_have_balance') : errorTarget ? $t('target_win_amount_less') : ''"
                  >
                  </div>
              </Message>
              <Message icon="null" severity="info" :closable="false">
                  <span class="c-text">
                     {{$t('available_balance')}}:
                  
                  </span>
                  <button type="button" @click="toMaxCount">
                      {{ getAvailableBalance }} {{ admin.currency }}
                  </button>
              </Message>
                <ValidationProvider
                  :name="$t('agent_id')"
                  rules="required"
                  v-slot="{ errors, validate, validated }"
                > 
                  <h4 class="c-text white-space d-flex align-center">
                    {{ $t('parent_agent') }}<span class="c-red">*</span>:
                    
                  </h4>
                  <div class="d-flex flex-column">
                    <v-menu v-model="parentMenu" 
                    offset-y
                    :close-on-content-click="false" 
                        nudge-left="0" 
                        nudge-right="0"
                        
                        >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-column" v-on="on">
                          <span class="p-input-icon-right"  >
                            <i class="pi pi-angle-down" />
                              <InputText 
                              :disabled="isEdit || admin.level !== 0"
                              :value="getParentName"
                              type="text" readonly class="p-inputtext-sm fw" 
                              :placeholder="$t('parent_agent')">
                            </InputText>
                          </span>
                        </div>
                        <!-- :disabled="isAgent" -->
                        </template>
                          <Listbox v-model="jackpot.agent_id" :options="agentsAndAdmin"
                          @change="parentMenu = false"
                          :filter="true"
                          class="custom-dropdown"
                          :emptyFilterMessage="$t('no_data')"
                          optionLabel="login" 
                          optionValue="id"
                          
                          style="width:auto">
                        <!-- <template #value="slotProps">
                          
                      </template>
                      <template #option="slotProps">
                         
                      </template> -->
                          </Listbox>
            
                      </v-menu>
                    <!-- <span v-if="errorStatus" class="p-error mt-1 font-weight-bold">{{ $t('required_field') }}</span> -->
                  </div>
              </ValidationProvider>
                <ValidationProvider
                  :name="$t('status')"
                  rules="required"
                  v-slot="{ errors, validate, validated }"
                > 
                  <h4 class="c-text white-space d-flex align-center">
                    {{ $t('jackpot_status') }}<span class="c-red">*</span>:
                    
                  </h4>
                  <div class="d-flex flex-column">
                    <v-menu v-model="statusMenu" 
                    offset-y
                    :close-on-content-click="false" 
                        nudge-left="0" 
                        nudge-right="0"
                        
                        >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex flex-column" v-on="on">
                          <span class="p-input-icon-right"  >
                            <i class="pi pi-angle-down" />
                              <InputText 
                              :disabled="jackpot.status === 'finished'"
                              :value="$t(jackpot.status)"
                              type="text" class="p-inputtext-sm fw" 
                              :placeholder="$t('status')">
                              </InputText>
                              </span>
                              </div>
                        </template>
                          <Listbox v-model="jackpot.status" :options="statuses"
                          @change="statusMenu = false"
                          :filter="true"
                          class="custom-dropdown"
                          :emptyFilterMessage="$t('no_data')"
                          optionLabel="name" 
                          optionValue="value"
                          
                          style="width:auto">
                        <!-- <template #value="slotProps">
                          
                      </template>
                      <template #option="slotProps">
                         
                      </template> -->
                          </Listbox>
            
                      </v-menu>
                    <span v-if="errorStatus" class="p-error mt-1 font-weight-bold">{{ $t('required_field') }}</span>
                  </div>
              </ValidationProvider>
                <ValidationProvider
                  :name="$t('start_win_amount')"
                  rules="required|min_value:0"
                  v-slot="{ errors, validate, validated }"
                > 
                  <h4 class="c-text white-space">{{ $t('start_win_amount') }}:</h4>
                  <div class="d-flex flex-column">
                    <InputNumber 
                    class="p-inputtext-sm fw"
                    :class="{ 'p-invalid' : errors.length > 0 }"
                     currencyDisplay="code"
                    mode="currency" 
                    :currency="getCurrencyValue"
                    :placeholder="$t('start_win_amount')" 
                    v-model="jackpot.start_win_amount" 
                    showButtons  
                    :step="1"
                    :min="1"
                    />
                    <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                  </div>
              </ValidationProvider>
                <ValidationProvider
                  :name="$t('target_win_amount')"
                  rules="required|min_value:1"
                  v-slot="{ errors, validate, validated }"
                > 
                  <h4 class="c-text white-space">{{ $t('target_win_amount') }}:</h4>
                  <div class="d-flex flex-column">
                    <InputNumber 
                    class="p-inputtext-sm fw"
                    :class="{ 'p-invalid' : errors.length > 0 }"
                     currencyDisplay="code"
                     
                    mode="currency" 
                    :currency="getCurrencyValue"
                    :placeholder="$t('target_win_amount')" 
                    v-model="jackpot.target_win_amount" 
                    showButtons  
                    :step="1"
                    :min="1"
                    />
                    <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                  </div>
              </ValidationProvider>
                <!-- <ValidationProvider
                  :name="$t('bets_progress')"
                  rules="required"
                  v-slot="{ errors, validate, validated }"
                > 
                  <h4 class="c-text white-space d-flex align-center">{{ $t('bets_progress') }}
                    <Badge value="?" severity="info" class="info-badge ml-1" v-tooltip.top="$t('bets_progress_hint')"></Badge>
                    :</h4>
                  <div class="d-flex flex-column">
                    <InputNumber 
                    class="p-inputtext-sm fw"
                    :class="{ 'p-invalid' : errors.length > 0 }"
                    :placeholder="$t('bets_progress')" 
                    v-model="jackpot.bets_progress" 
                    currencyDisplay="code"
                    mode="currency" 
                    :currency="getCurrencyValue"
                    showButtons  
                    :step="1"
                    :min="1"
                    />
                    <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                    <span v-if="errorProgressStart || errorProgressTarget" class="p-error mt-1 font-weight-bold">{{ errorProgressStart ? $t('error_progress_start') : errorProgressTarget ? $t('error_progress_target') : '' }}</span>
                  </div>
              </ValidationProvider> -->
              <ValidationProvider
                  :name="$t('bet_percent')"
                  rules="required"
                  v-slot="{ errors, validate, validated }"
                > 
                <h4 class="c-text white-space d-flex align-center">{{ $t('bet_percent') }}(50% {{ $t('max')}})<Badge value="?" severity="info" class="info-badge ml-1" v-tooltip.top="$t('bet_percent_hint')"></Badge>
                  :</h4>
                  <div class="d-flex flex-column">
                    <InputNumber 
                    class="p-inputtext-sm fw"
                    :class="{ 'p-invalid' : errors.length > 0 }"
                    mode="decimal" 
                    :useGrouping="false"
                    :placeholder="$t('bet_percent')" 
                    v-model="jackpot.bet_percent" 
                    showButtons  
                    suffix=" %"
                    :step="1"
                    :min="1"
                    />
                    <!-- :max="50" -->
                    <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                    <span v-if="errorPercent" class="p-error mt-1 font-weight-bold">{{ $t('bet_percent_limit') }}</span>
                  </div>
              </ValidationProvider>  
              <!-- <ValidationProvider
                  :name="$t('bet_percent')"
                  rules="required|min_value:1|max_value:50"
                  v-slot="{ errors, validate, validated }"
                > 
                  <h4 class="c-text white-space d-flex align-center">{{ $t('bet_percent') }}(50% {{ $t('max')}})<Badge value="?" severity="info" class="info-badge ml-1" v-tooltip.top="$t('bet_percent_hint')"></Badge>
                    :</h4>
                  <div class="d-flex flex-column">
                    <InputNumber 
                    class="p-inputtext-sm fw"
                    :class="{ 'p-invalid' : errors.length > 0 }"
                    :placeholder="$t('bet_percent')" 
                    v-model="jackpot.bet_percent" 
                    showButtons  
                    :step="1"
                    :min="1"
                    :max="50"
                    />
                    <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                  </div>
              </ValidationProvider> -->
              <span>
                <h4 class="c-text white-space">
                  <p class="mb-0">{{ $t('currency') }}<span class="c-red">*</span>:</p>
                </h4>
                          <v-menu v-model="currencyMenu" 
                offset-y
                :close-on-content-click="false" 
                    nudge-left="0" 
                    nudge-right="0"
                    
                    >
                    <template v-slot:activator="{ on }">
                      <div class="d-flex flex-column" v-on="on">
                      <span class="p-input-icon-right"  >
                        <i class="pi pi-angle-down" />
                          <InputText 
                          disabled
                          :value="getCurrencyValue"
                          type="text" readonly class="p-inputtext-sm fw" :placeholder="$t('currency')">
                          </InputText>
                          </span>
                          </div>
                    </template>
                      <Listbox v-model="jackpot.currency" :options="currencies"
                      :filter="true"
                      @change="currencyMenu = false"
                      class="custom-dropdown"
                      :emptyFilterMessage="$t('no_data')"
                      style="width:auto" />
        
                  </v-menu>
                          </span>
              </v-card-text>
              <v-card-actions>
                  <Button 
                  @click="handleSubmit(validate)"
                  :disabled="formLoading"
                  :loading="formLoading"
                     :label="isEdit ? $t('edit') : $t('create')"
                      icon="pi pi-save" class="p-button-sm p-button-success fw" />
              </v-card-actions>
            </ValidationObserver>
            </v-card>
        </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

    export default {
        name: 'ModalJackpotHandle',
        components:{
        },
        props: {
          currentJackpot: {
                type: Object,
                default: () => {}
            },
            isEdit: {
              type: Boolean,
            },
            toAddDialog: {
                type: Boolean,
                default: false
            },
        },
        computed:{
          ...mapGetters({
            currencies: 'getFilteredCurrencies',
            agents: 'agents/getAgents',
            admin: 'admin',
            tokenUser: 'tokenUser',
          }),
          getCurrencyValue(){
              return this.admin.currency;
          },
          getAvailableBalance(){
            if (this.jackpot.agent_id === this.admin.id) {
              return this.admin.credits_balance;
            } else if (this.agents.items && this.agents.items.length > 0) {
              const parentAgent = this.agents.items.find(agent => agent.id === this.jackpot.agent_id);
              if (parentAgent) {
                return parentAgent.credits_balance;
              }
            }
          },
          getParentName(){
              if (this.agents.items && this.agents.items.length > 0) {
      const parentAgent = this.agents.items.find(agent => agent.id === this.jackpot.agent_id);
      if (parentAgent) {
        return parentAgent.login;
      } else{
             return this.admin.login;
           }
           } else{
             return this.admin.login;
           }
          },
          isAgent(){
            if (this.tokenUser === 'agent') {
              return 'agent';
            } else{
              return null;
            }
          },
        },
        data() {
            return {
              parentMenu: false,
              statusMenu: false,
                formLoading: false,
                currencyMenu: false,
                dialog: false,
                showAdd: true,
                errorCount: false,
                errorProgressStart: false,
                errorProgressTarget: false,
                errorPercent: false,
                errorTarget: false,
                balanceCheck: 0,
                errorStatus: false,
                statuses: [
                  {name: this.$t('created'), value: 'created'},
                  {name: this.$t('ongoing'), value: 'ongoing'},
                ],
                 jackpot: {
                   agent_id: 0,
                   bet_percent: 1,
                   currency: 'USD',
                   bets_progress: 1,
                   start_win_amount: 1,
                   target_win_amount: 1,
                   status: "created",
                 },
                 agentsAndAdmin: [],
            }
        },
        async mounted() {
          // console.log('this.admin.id', this.admin.id);
          // console.log('this.isAgent', this.isAgent);
          if (this.isAgent && this.admin.id) {
            this.jackpot.agent_id = this.admin.id; 
          }
        },
        watch:{
          'agents.items': {
            handler(newVal) {
              if (newVal && newVal.length > 0) {
                this.agentsAndAdmin = [...this.agents.items];
                this.agentsAndAdmin.push(this.admin);
              }
            },
            deep: true,
            immediate: true
          },
          'jackpot.status': {
            handler(newVal, oldVal) {
              if (oldVal === 'ongoing' && !newVal) {
                this.jackpot.status = 'ongoing';     
              } else if (!newVal) {
                this.jackpot.status = 'created';       
              }
            }
          },
          dialog: {
            handler(newVal) {
              if (newVal === false) {
                this.$emit('dialog');
                this.resetJackpotInfo();
                if (this.toAddDialog) {
                  this.errorStatus = false;
                  this.errorCount = false;
                  this.errorProgressStart = false;
                  this.errorProgressTarget = false;
                  this.errorTarget = false;
                  this.$nextTick(() => {
                    
                    this.$refs.observer.reset();
                  })
                }
              }
            },
            immediate: true
          },
          toAddDialog: {
            handler(newVal) {
              this.dialog = newVal;
            },
            immediate: true
          },
          currentJackpot: {
            handler(newVal) {
              if (Object.keys(newVal).length > 0) {
                this.jackpot = newVal;
                
              } else{
                this.resetJackpotInfo();
              }
            },
            deep: true
          }
        },
        methods: {
          toMaxCount() {
            this.jackpot.target_win_amount = this.getAvailableBalance;
          },
          handleSubmit(validate) {
            validate().then(valid => {
        if (valid) {
          this.errorProgressStart = false;
          this.errorProgressTarget = false;
          this.errorStatus = false;
          this.errorPercent = false;
          this.errorCount = false;
          this.errorTarget = false;
          if (!this.jackpot.status) {
            this.errorStatus = true;
            return;
          }
          if (this.jackpot.target_win_amount > this.getAvailableBalance) {
                this.errorCount = true;
            }
            else if (this.jackpot.target_win_amount <= this.jackpot.start_win_amount) {
                
                this.errorTarget = true
              
            } else if (this.jackpot.bet_percent > 50 || this.jackpot.bet_percent < 1) {
              this.errorPercent = true;
            }
            else{
                this.errorCount = false
                this.createJackpot();
            }
        }
      });
    },
          closeAll(){
            this.$emit('dialog');
            this.dialog = false;
            this.showAdd = true;
          },
            async createJackpot(){
          this.formLoading = true;
          try {
            const create = this.jackpot;
            const jackpotData = {
              id: create.id,
              agent_id: create.agent_id,
              bet_percent: create.bet_percent,
              currency: this.getCurrencyValue,
              // bets_progress: create.bets_progress,
              start_win_amount: create.start_win_amount,
              target_win_amount: create.target_win_amount,
              status: create.status,
            }
            let endpoint 
            if (this.isEdit){
              endpoint = 'jackpots/awaitEditJackpot';
            }else{
              endpoint = 'jackpots/awaitCreateJackpot';
            }
            await this.$store.dispatch(endpoint, jackpotData);
            this.$toast.add({severity: 'success', summary: this.$t('jackpot_handled') , life: 4000}) 
            this.$emit('added')
            await this.$store.dispatch('getAgentStatus');
          } catch (error) {
            if (error.response.data.detail === 'Can\'t create jackpot with Ongoing status, we already have active jackpot') {
              this.jackpot.status = 'created';
              this.$toast.add({severity: 'error', summary: this.$t('have_active_jackpot'), life: 4000}) 
            } else{
              this.$toast.add({severity: 'error', summary: this.$t('just_error'), detail: error.message, life: 4000}) 
            }
            
          } finally{

            this.formLoading = false; 
          }
            // this.showAdd = false;
        },
        resetJackpotInfo(){
          this.jackpot =  {
              agent_id: this.isAgent ? this.admin.id : 0,
              bet_percent: 1,
              currency: 'USD',
              bets_progress: 1,
              start_win_amount: 1,
              target_win_amount: 1,
              status: "created",
            };
        },
        },
    }
</script>

<style lang="scss" scoped>
.v-card__text{
  >span{
    display: flex;
    align-items: center;
    //justify-content: space-between;
    h4{
      flex: 1 1 auto;
    }
    >div{
      flex: 0 0 300px;
    }
    .p-component{
      max-width: 300px;
      //flex: 0 0 300px;
    }
  }
}
</style>